$(".header_burger").click(function (event) {
    $(".header_burger, .header-menu").toggleClass("active");
    $('.mainscreen-content').fadeToggle();
    $("body").toggleClass("lock");
  });

  $(".window-call").click(function (event) {
    $(".side-call").toggleClass('active');
  });
  $(".side-call svg").click(function (event) {
    $(".side-call").removeClass('active');
  });